
.monitoring-data {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    .monitoring-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        label {
            font-size: 16px;
            margin-right: 20px;
        }
        .w420-cascader {
            width: 420px;
        }
    }
    .main-wrapper {
        margin-top: 34px;
        .optimize-title {
            font-size: 16px;
            color: #333;
            position: relative;
            padding: 0 0 0 16px;
            margin-bottom: 20px;
            &:before {
                content: "";
                position: absolute;
                width: 4px;
                height: 16px;
                background: #2dc079;
                left: 0;
                top: 4px;
            }
        }
        .monitoring-info {
            .monitoring-input-title {
                display: flex;
                justify-content: flex-start;
                margin: 10px 0;
                .text {
                    display: block;
                    width: 270px;
                    margin-right: 40px;
                    text-align: center;
                    &:nth-child(1) {
                        margin-left: 42px;
                    }
                    &:nth-child(2) {
                        width: 195px;
                        margin-right: 46px;
                    }
                    &:nth-child(3) {
                        width: 247px;
                    }
                    &:nth-child(4) {
                        width: 247px;
                    }
                }
            }

            .search-column {
                display: flex;
                justify-content: flex-start;
                .search-column-one {
                    width: 274px;
                    min-width: 274px;
                    margin-right: 30px;
                    &:nth-child(2) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .required{
        &:after{
            display: block;
            content: '必须填';
            position: absolute;
            bottom: -30px;
            left: calc(50% - 16px);
            font-size: 14px;
            color: #ff0000;
        }
    }
}
