
.independent-sales{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    .add-btn-left{
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
    }
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }
    .dialog-footer{
        margin-top: 30px;
        text-align: center;
    }
}
